import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from 'vue3-scroll-spy';
import { vMaska } from "maska"
import i18n from "./i18n"
import BootstrapVueNext from 'bootstrap-vue-next'
import DisableAutocomplete from '@aacassandra/vue-disable-autocomplete';
import VueGoogleMaps from '@fawmi/vue-google-maps';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import "@/assets/scss/app.scss";
import "@vueform/multiselect/themes/default.css"

// PINIA
import pinia from '@/state/pinia'

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';


createApp(App)
    .use(router)
    .use(pinia)
    .use(require('vue-chartist'))
    .use(BootstrapVueNext)
    .use(VueApexCharts)
    .use(vClickOutside)
    .use(VueGoogleMaps, {
        load: {
            key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
            language: 'th',
        },
        
    })
    .use(i18n)
    .use(DisableAutocomplete)
    .use(registerScrollSpy)
    .directive("maska", vMaska)
    .mount('#app')
