import { useAuthStore } from "@/state/pinia";
export default [
  {
    path: "/",
    name: "default",
    meta: { title: "Dashboard", authRequired: true },
    component: () => import("../views/dashboards/default")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login"),
    meta: {
      title: "Login",
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/account/logout"),
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        const auth = useAuthStore();
        auth.logout();
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      }
    }
  },
  {
    path: "/404",
    name: "404",
    meta: { title: "404 Error Page" },
    component: require("../views/utility/404").default
  },
  {
    path: "/systemconfig",
    name: "System",
    meta: { title: "ตั้งค่าระบบ", authRequired: true },
    component: () => import("../views/system/system-config"),
  },
  {
    path: "/users/create",
    name: "Create User",
    meta: { title: "สร้างผู้ใช้งาน", authRequired: true },
    component: () => import("../views/account/user-create")
  },
  {
    path: "/users/:id",
    name: "User Detail",
    meta: { title: "รายละเอียดผู้ใช้งาน", authRequired: true },
    component: () => import("../views/account/user-edit")
  },
  {
    path: "/users",
    name: "Users",
    meta: { title: "รายการผู้ใช้งาน", authRequired: true },
    component: () => import("../views/account/user-list"),
  },
  {
    path: "/vehicles",
    name: "Vehicles",
    meta: { title: "รายการรถบรรทุก", authRequired: true },
    component: () => import("../views/vehicle/vehicle-list"),
  },
  {
    path: "/vehicles/:id",
    name: "Vehicle Detail",
    meta: { title: "รายละเอียดรถบรรทุก", authRequired: true },
    component: () => import("../views/vehicle/vehicle-edit")
  },
  {
    path: "/vehicles/create",
    name: "Create Vehicle",
    meta: { title: "สร้างรถบรรทุก", authRequired: true },
    component: () => import("../views/vehicle/vehicle-create")
  },
  {
    path: "/employers",
    name: "Employers",
    meta: { title: "รายการผู้ว่าจ้าง", authRequired: true },
    component: () => import("../views/employer/employer-list"),
  },
  {
    path: "/employers/:id",
    name: "Employer Detail",
    meta: { title: "รายละเอียดผู้ว่าจ้าง", authRequired: true },
    component: () => import("../views/employer/employer-edit")
  },
  {
    path: "/employers/create",
    name: "Create Employer",
    meta: { title: "สร้างผู้ว่าจ้าง", authRequired: true },
    component: () => import("../views/employer/employer-create")
  },
  {
    path: "/routes",
    name: "Routes",
    meta: { title: "รายการเส้นทาง", authRequired: true },
    component: () => import("../views/route/route-list"),
  },
  {
    path: "/routes/:id",
    name: "Route Detail",
    meta: { title: "รายละเอียดเส้นทาง", authRequired: true },
    component: () => import("../views/route/route-edit")
  },
  {
    path: "/routes/create",
    name: "Create Route",
    meta: { title: "สร้างเส้นทาง", authRequired: true },
    component: () => import("../views/route/route-create")
  },
  {
    path: "/taskmanagement",
    name: "Task Management",
    meta: { title: "สถานะการทำงานของรถขนส่ง", authRequired: true },
    component: () => import("../views/operation/task-management"),
  },
  {
    path: "/taskverify",
    name: "Task Verify",
    meta: { title: "ตรวจสอบและอนุมัติใบงาน", authRequired: true },
    component: () => import("../views/operation/task-verify"),
  },
  {
    path: "/task/:id",
    name: "Task Detail",
    meta: { title: "รายละเอียดใบงาน", authRequired: true },
    component: () => import("../views/operation/task-edit")
  },
  {
    path: "/generate-driver-payment",
    name: "Generate Driver Payment",
    meta: { title: "สร้างใบสรุปค่าใช้จ่ายคนขับรถ", authRequired: true },
    component: () => import("../views/payment/generate-driver-payment")
  },
  {
    path: "/manage-driver-payment",
    name: "Manage Driver Payment",
    meta: { title: "จัดการใบสรุปค่าใช้จ่ายคนขับรถ", authRequired: true },
    component: () => import("../views/payment/manage-driver-payment")
  },
  {
    path: "/driver-payment/:id",
    name: "Driver Payment Detail",
    meta: { title: "ใบสรุปค่าใช้จ่ายคนขับรถ", authRequired: true },
    component: () => import("../views/payment/driver-payment-detail")
  },
  {
    path: "/generate-employer-bill",
    name: "Generate Employer Bill",
    meta: { title: "สร้างใบวางบิล", authRequired: true },
    component: () => import("../views/payment/generate-employer-bill")
  },
  {
    path: "/manage-employer-bill",
    name: "Manage Employer Bill",
    meta: { title: "จัดการใบวางบิล", authRequired: true },
    component: () => import("../views/payment/manage-employer-bill")
  },
  {
    path: "/employer-bill/:id",
    name: "Employer Bill Detail",
    meta: { title: "ใบวางบิล", authRequired: true },
    component: () => import("../views/payment/employer-bill-detail")
  },
];
