import { defineStore } from "pinia";
import { apiService } from '../../helpers/APIServices/service';
import router from '../../router/index'
import { useNotificationStore } from "./notification"

const user = JSON.parse(localStorage.getItem('user'));

export const useAuthStore = defineStore("auth", {
    state: () => ({
        status: user ? { loggeduser: true } : {},
        user: user || null
    }),
    actions: {
        login(username, password) {
            this.loginRequest()
            apiService.login(username, password)
                .then(
                    (user) => {
                        this.loginSuccess(user)
                        router.push('/');
                    },
                    (error) => {
                        this.loginFailure()
                        const notificationStore = useNotificationStore(); 
                        notificationStore.error(error);
                    }
                );

        },
        logout() {
            apiService.logout();
            this.status = {};
            this.user = null;
        },
        loginRequest() {
            this.status = { loggingIn: true }
        },
        loginSuccess(user) {
            this.status = { loggeduser: true };
            this.user = user;
        },
        loginFailure() {
            this.status = {};
            this.user = null;
        },
    },
})