import { authHeader } from './auth-header';


export const apiService = {
    url : process.env.VUE_APP_API_URL + 'api/v1',
    login,
    logout,
    getMe,
    getUsers,
    createUser,
    uploadImage,
    deleteUser,
    updateUser,
    getUser,
    getRoles,
    getVehicles,
    getVehicle,
    createVehicle,
    updateVehicle,
    deleteVehicle,
    getEmployers,
    getEmployer,
    createEmployer,
    updateEmployer,
    deleteEmployer,
    getRoutes,
    getRoute,
    createRoute,
    updateRoute,
    deleteRoute,
    getOperationTasksForDriverPayment,
    getOperationTasksForEmployerBill,
    getOperationTasks,
    getOperationTask,
    createOperationTask,
    updateOperationTask,
    deleteOperationTask,
    getVehicleTaskStatuses,
    getOperationTaskFuels,
    createOperationTaskFuel,
    updateOperationTaskFuel,
    deleteOperationTaskFuel,
    getOperationTaskCosts,
    createOperationTaskCost,
    updateOperationTaskCost,
    deleteOperationTaskCost,
    getOperationTaskDocuments,
    createOperationTaskDocument,
    updateOperationTaskDocument,
    deleteOperationTaskDocument,
    getOperationTaskReservePayments,
    createOperationTaskReservePayment,
    updateOperationTaskReservePayment,
    deleteOperationTaskReservePayment,
    getDrivers,
    getOperationUsers,
    getCountByStatus,
    getMeWithCountTask,
    generateDriverPayment,
    getDriverPayment,
    getDriverPayments,
    deleteDriverPayment,
    updateFineCost,
    generateEmployerBill,
    getEmployerBills,
    getEmployerBill,
    deleteEmployerBill,
    downloadTaskCashAdvances,
    getStatuses,
    updateDriverBillVerifyStatus,
    updateEmployerBillVerifyStatus,
    updateDriverBillApproveStatus,
    updateEmployerBillApproveStatus,
    updateDriverBillBilledStatus,
    updateEmployerBillBilledStatus,
    exportEmployerBills,
    exportDriverPayments,
    getBillStatuses,
    systemGetMobileAppVersion,
    systemGetSystemConfig,
    updateSystemConfig,
};

function systemGetMobileAppVersion() {
    return fetch(apiService.url + '/system/mobile-app-version', {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(mobileAppVersion => {
            return mobileAppVersion;
        });
}

function systemGetSystemConfig() {
    return fetch(apiService.url + '/system/system-config', {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(systemConfig => {
            return systemConfig;
        });
}

function updateSystemConfig(systemConfig) {
    return fetch(apiService.url + '/system/update-system-config', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(systemConfig)
    })
        .then(handleResponse)
        .then(systemConfig => {
            return systemConfig;
        });
}

function getBillStatuses() {
    return fetch(apiService.url + '/bill-statuses', {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(billStatuses => {
            return billStatuses;
        });
}

function exportEmployerBills(page=1, search="", filter="", sort="", order="", limit=1000) {
    const data = new URLSearchParams();
    data.append('page', page);
    data.append('search', search);
    data.append('filter', filter);
    data.append('sort', sort);
    data.append('order', order);
    data.append('limit', limit);
    return fetch(apiService.url + '/payment/export-employer-bills?'+data, {
        method: 'GET',
        headers: authHeader(),
        responseType: 'blob',
    })
        .then(res => res.blob())
        .then(blob => {
            return blob;
        });
}

function exportDriverPayments(page=1, search="", filter="", sort="", order="", limit=1000) {
    const data = new URLSearchParams();
    data.append('page', page);
    data.append('search', search);
    data.append('filter', filter);
    data.append('sort', sort);
    data.append('order', order);
    data.append('limit', limit);
    return fetch(apiService.url + '/payment/export-driver-payments?'+data, {
        method: 'GET',
        headers: authHeader(),
        responseType: 'blob',
    })
        .then(res => res.blob())
        .then(blob => {
            return blob;
        });
}

function updateDriverBillVerifyStatus (driverBill) {
    return fetch(apiService.url + '/payment/update-driver-bill-verify-status', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(driverBill)
    })
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

function updateEmployerBillVerifyStatus (employerBill) {
    return fetch(apiService.url + '/payment/update-employer-bill-verify-status', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(employerBill)
    })  
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

function updateDriverBillApproveStatus (driverBill) {
    return fetch(apiService.url + '/payment/update-driver-bill-approve-status', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(driverBill)
    })
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

function updateEmployerBillApproveStatus (employerBill) {
    return fetch(apiService.url + '/payment/update-employer-bill-approve-status', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(employerBill)
    })
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

function updateDriverBillBilledStatus (driverBill) {
    return fetch(apiService.url + '/payment/update-driver-bill-billed-status', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(driverBill)
    })
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

function updateEmployerBillBilledStatus (employerBill) {
    return fetch(apiService.url + '/payment/update-employer-bill-billed-status', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(employerBill)
    })
        .then(handleResponse)
        .then(res => {
            return res;
        });
}


function getStatuses() {
    return fetch(apiService.url + '/statuses', {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(statuses => {
            return statuses;
        });
}

function downloadTaskCashAdvances(page=1, search="", filter="", sort="", order="", limit=1000) {
    const data = new URLSearchParams();
    data.append('page', page);
    data.append('search', search);
    data.append('filter', filter);
    data.append('sort', sort);
    data.append('order', order);
    data.append('limit', limit);
    return fetch(apiService.url + '/operation-tasks/cash-advances?'+data, {
        method: 'GET',
        headers: authHeader(),
        responseType: 'blob',
    })
        .then(res => res.blob())
        .then(blob => {
            return blob;
        });
}

function getOperationTasksForEmployerBill(page=1, search="", filter="", sort="", order="", limit=10) {
    const data = new URLSearchParams();
    data.append('page', page);
    data.append('search', search);
    data.append('filter', filter);
    data.append('sort', sort);
    data.append('order', order);
    data.append('limit', limit);
    return fetch(apiService.url + '/operation-tasks/employer-bill?'+data, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(operationTasks => {
            return operationTasks;
        });
}

function generateEmployerBill(request){
    return fetch(apiService.url + '/payment/employer-bill', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(request)
    })
        .then(handleResponse)
        .then(employerBill => {
            return employerBill;
        });

}

function getEmployerBills(page=1, search="", filter="", sort="", order="", limit=10) {
    const data = new URLSearchParams();
    data.append('page', page);
    data.append('search', search);
    data.append('filter', filter);
    data.append('sort', sort);
    data.append('order', order);
    data.append('limit', limit);
    return fetch(apiService.url + '/payment/employer-bills?'+data, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(employerBills => {
            return employerBills;
        });
}

function getEmployerBill(id) {
    return fetch(apiService.url + '/payment/employer-bills/'+id, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(employerBill => {
            return employerBill;
        });
}

function deleteEmployerBill(id) {
    return fetch(apiService.url + '/payment/employer-bills/'+id, {
        method: 'DELETE',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(employerBill => {
            return employerBill;
        });
}


function deleteDriverPayment(id) {
    return fetch(apiService.url + '/payment/driver-payments/'+id, {
        method: 'DELETE',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(driverPayment => {
            return driverPayment;
        });
}

function getDriverPayments(page=1, search="", filter="", sort="", order="", limit=10) {
    const data = new URLSearchParams();
    data.append('page', page);
    data.append('search', search);
    data.append('filter', filter);
    data.append('sort', sort);
    data.append('order', order);
    data.append('limit', limit);
    return fetch(apiService.url + '/payment/driver-payments?'+data, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(driverPayments => {
            return driverPayments;
        });
}

function updateFineCost (fineCost) {
    return fetch(apiService.url + '/payment/update-fine-cost', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(fineCost) 
    })
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

function getDriverPayment(id) {
    return fetch(apiService.url + '/payment/driver-payments/'+id, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(driverPayment => {
            return driverPayment;
        });

}

function generateDriverPayment(driverPaymentRequest){
    return fetch(apiService.url + '/payment/driver-payment', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(driverPaymentRequest)
    })
        .then(driverPayment => {
            return driverPayment;
        });

}

function login(username, password) {
    
    return fetch(apiService.url + '/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    })
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        });
}

function getMe() {
    return fetch(apiService.url + '/me', {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

function getUsers(page=1, search="", filter="", sort="", order="", limit=1000) {
    const data = new URLSearchParams();
    data.append('page', page);
    data.append('search', search);
    data.append('filter', filter);
    data.append('sort', sort);
    data.append('order', order);
    data.append('limit', limit);
    return fetch(apiService.url + '/users?'+data, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(users => {
            return users;
        })
}

function getUser(id) {
    return fetch(apiService.url + '/users/'+id, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

function getRoles(page=1, search="", sort="", order="", limit=1000) {
    const data = new URLSearchParams();
    data.append('page', page);
    data.append('search', search);
    data.append('sort', sort);
    data.append('order', order);
    data.append('limit', limit);
    return fetch(apiService.url + '/roles?'+data, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(roles => {
            return roles;
        });
}

function createUser(user) {
    return fetch(apiService.url + '/users', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    })
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

function deleteUser(id) {
    return fetch(apiService.url + '/users/'+id, {
        method: 'DELETE',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

function updateUser(user) {
    return fetch(apiService.url + '/users/'+user.ID, {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(user)
    })
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

function uploadImage(image, filepath, filename = "") {
    const data = new URLSearchParams();
    data.append('filepath', filepath);
    data.append('filename', filename);
    return fetch(apiService.url + '/upload?'+data, {
        method: 'POST',
        headers: authHeader(),
        body: image
    })
        .then(handleResponse)
        .then(image => {
            return image;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getVehicles(page=1, search="", filter="", sort="", order="", limit=1000) {
    const data = new URLSearchParams();
    data.append('page', page);
    data.append('search', search);
    data.append('filter', filter);
    data.append('sort', sort);
    data.append('order', order);
    data.append('limit', limit);
    return fetch(apiService.url + '/vehicles?'+data, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(vehicles => {
            return vehicles;
        });
}

function getVehicle(id) {
    return fetch(apiService.url + '/vehicles/'+id, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(vehicle => {
            return vehicle;
        });
}

function createVehicle(vehicle) {
    return fetch(apiService.url + '/vehicles', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(vehicle)
    })
        .then(handleResponse)
        .then(vehicle => {
            return vehicle;
        });
}

function updateVehicle(vehicle) {
    return fetch(apiService.url + '/vehicles/'+vehicle.ID, {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(vehicle)
    })
        .then(handleResponse)
        .then(vehicle => {
            return vehicle;
        });
}

function deleteVehicle(id) {
    return fetch(apiService.url + '/vehicles/'+id, {
        method: 'DELETE',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(vehicle => {
            return vehicle;
        });
}

function getEmployers(page=1, search="", filter="", sort="", order="", limit=10) {
    const data = new URLSearchParams();
    data.append('page', page);
    data.append('search', search);
    data.append('filter', filter);
    data.append('sort', sort);
    data.append('order', order);
    data.append('limit', limit);
    return fetch(apiService.url + '/employers?'+data, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(employers => {
            return employers;
        });
}

function getEmployer(id) {
    return fetch(apiService.url + '/employers/'+id, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(employer => {
            return employer;
        });
}

function createEmployer(employer) {
    return fetch(apiService.url + '/employers', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(employer)
    })
        .then(handleResponse)
        .then(employer => {
            return employer;
        });
}

function updateEmployer(employer) {
    return fetch(apiService.url + '/employers/'+employer.ID, {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(employer)
    })
        .then(handleResponse)
        .then(employer => {
            return employer;
        });
}

function deleteEmployer(id) {
    return fetch(apiService.url + '/employers/'+id, {
        method: 'DELETE',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(employer => {
            return employer;
        });
}

function getRoutes(page=1, search="", filter="", sort="", order="", limit=1000) {
    const data = new URLSearchParams();
    data.append('page', page);
    data.append('search', search);
    data.append('filter', filter);
    data.append('sort', sort);
    data.append('order', order);
    data.append('limit', limit);
    return fetch(apiService.url + '/routes?'+data, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(routes => {
            return routes;
        });
}

function getRoute(id) {
    return fetch(apiService.url + '/routes/'+id, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(route => {
            return route;
        });
}

function createRoute(route) {
    return fetch(apiService.url + '/routes', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(route)
    })
        .then(handleResponse)
        .then(route => {
            return route;
        });
}

function updateRoute(route) {
    return fetch(apiService.url + '/routes/'+route.ID, {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(route)
    })
        .then(handleResponse)
        .then(route => {
            return route;
        });
}

function deleteRoute(id) {
    return fetch(apiService.url + '/routes/'+id, {
        method: 'DELETE',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(route => {
            return route;
        });
}

function getCountByStatus() {
    return fetch(apiService.url + '/operation-tasks/count-by-status', {
        method: 'GET',
        headers: authHeader()
    })

        .then(handleResponse)
        .then(countByStatus => {
            return countByStatus;
        });
}

function getOperationTasksForDriverPayment(page=1, search="", filter="", sort="", order="", limit=10) {
    const data = new URLSearchParams();
    data.append('page', page);
    data.append('search', search);
    data.append('filter', filter);
    data.append('sort', sort);
    data.append('order', order);
    data.append('limit', limit);
    return fetch(apiService.url + '/operation-tasks/driver-payment?'+data, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(operationTasks => {
            return operationTasks;
        });
}

function getOperationTasks(page=1, search="", filter="", sort="", order="", limit=10) {
    const data = new URLSearchParams();
    data.append('page', page);
    data.append('search', search);
    data.append('filter', filter);
    data.append('sort', sort);
    data.append('order', order);
    data.append('limit', limit);
    return fetch(apiService.url + '/operation-tasks?'+data, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(operationTasks => {
            return operationTasks;
        });
}

function getOperationTask(id) {
    return fetch(apiService.url + '/operation-tasks/'+id, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(operationTask => {
            return operationTask;
        });
}

function createOperationTask(operationTask) {
    return fetch(apiService.url + '/operation-tasks', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(operationTask)
    })
        .then(handleResponse)
        .then(operationTask => {
            return operationTask;
        });
}

function updateOperationTask(operationTask) {
    return fetch(apiService.url + '/operation-tasks/'+operationTask.ID, {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(operationTask)
    })
        .then(handleResponse)
        .then(operationTask => {
            return operationTask;
        });
}

function deleteOperationTask(id) {
    return fetch(apiService.url + '/operation-tasks/'+id, {
        method: 'DELETE',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(operationTask => {
            return operationTask;
        });
}

function getVehicleTaskStatuses(page=1, search="", sort="", order="", limit=1000) {
    const data = new URLSearchParams();
    data.append('page', page);
    data.append('search', search);
    data.append('sort', sort);
    data.append('order', order);
    data.append('limit', limit);
    return fetch(apiService.url + '/vehicles/task-status?'+data, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(vehicleTaskStatuses => {
            return vehicleTaskStatuses;
        });
}

function getOperationTaskReservePayments(taskid=0,page=1, search="", sort="", order="", limit=10) {
    const data = new URLSearchParams();
    data.append('taskid', taskid);
    data.append('page', page);
    data.append('search', search);
    data.append('sort', sort);
    data.append('order', order);
    data.append('limit', limit);
    return fetch(apiService.url + '/mobile/operation-task-reserve-payments?'+data, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(operationTaskReservePayments => {
            return operationTaskReservePayments;
        });
}

function createOperationTaskReservePayment(operationTaskReservePayment) {
    return fetch(apiService.url + '/mobile/operation-task-reserve-payments', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(operationTaskReservePayment)
    })
        .then(handleResponse)
        .then(operationTaskReservePayment => {
            return operationTaskReservePayment;
        });
}

function updateOperationTaskReservePayment(operationTaskReservePayment) {
    return fetch(apiService.url + '/mobile/operation-task-reserve-payments/'+operationTaskReservePayment.ID, {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(operationTaskReservePayment)
    })
        .then(handleResponse)
        .then(operationTaskReservePayment => {
            return operationTaskReservePayment;
        });
}

function deleteOperationTaskReservePayment(id) {
    return fetch(apiService.url + '/mobile/operation-task-reserve-payments/'+id, {
        method: 'DELETE',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(operationTaskReservePayment => {
            return operationTaskReservePayment;
        });
}

function getOperationTaskFuels(taskid=0, page=1, search="", sort="", order="", limit=10) {
    const data = new URLSearchParams();
    data.append('taskid', taskid);
    data.append('page', page);
    data.append('search', search);
    data.append('sort', sort);
    data.append('order', order);
    data.append('limit', limit);
    return fetch(apiService.url + '/mobile/operation-task-fuels?'+data, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(operationTaskFuels => {
            return operationTaskFuels;
        });
}

function createOperationTaskFuel(operationTaskFuel) {
    return fetch(apiService.url + '/mobile/operation-task-fuels', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(operationTaskFuel)
    })
        .then(handleResponse)
        .then(operationTaskFuel => {
            return operationTaskFuel;
        });
}

function updateOperationTaskFuel(operationTaskFuel) {
    return fetch(apiService.url + '/mobile/operation-task-fuels/'+operationTaskFuel.ID, {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(operationTaskFuel)
    })
        .then(handleResponse)
        .then(operationTaskFuel => {
            return operationTaskFuel;
        });
}

function deleteOperationTaskFuel(id) {
    return fetch(apiService.url + '/mobile/operation-task-fuels/'+id, {
        method: 'DELETE',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(operationTaskFuel => {
            return operationTaskFuel;
        });
}

function getOperationTaskCosts(taskid=0, page=1, search="", sort="", order="", limit=10) {
    const data = new URLSearchParams();
    data.append('taskid', taskid);
    data.append('page', page);
    data.append('search', search);
    data.append('sort', sort);
    data.append('order', order);
    data.append('limit', limit);
    return fetch(apiService.url + '/mobile/operation-task-costs?'+data, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(operationTaskCosts => {
            return operationTaskCosts;
        });
}

function createOperationTaskCost(operationTaskCost) {
    return fetch(apiService.url + '/mobile/operation-task-costs', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(operationTaskCost)
    })
        .then(handleResponse)
        .then(operationTaskCost => {
            return operationTaskCost;
        });
}

function updateOperationTaskCost(operationTaskCost) {
    return fetch(apiService.url + '/mobile/operation-task-costs/'+operationTaskCost.ID, {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(operationTaskCost)
    })
        .then(handleResponse)
        .then(operationTaskCost => {
            return operationTaskCost;
        });
}

function deleteOperationTaskCost(id) {
    return fetch(apiService.url + '/mobile/operation-task-costs/'+id, {
        method: 'DELETE',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(operationTaskCost => {
            return operationTaskCost;
        });
}

function getOperationTaskDocuments(taskid=0, page=1, search="", sort="", order="", limit=10) {
    const data = new URLSearchParams();
    data.append('taskid', taskid);
    data.append('page', page);
    data.append('search', search);
    data.append('sort', sort);
    data.append('order', order);
    data.append('limit', limit);
    return fetch(apiService.url + '/mobile/operation-task-documents?'+data, {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(operationTaskDocuments => {
            return operationTaskDocuments;
        });
}

function createOperationTaskDocument(operationTaskDocument) {
    return fetch(apiService.url + '/mobile/operation-task-documents', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(operationTaskDocument)
    })
        .then(handleResponse)
        .then(operationTaskDocument => {
            return operationTaskDocument;
        });
}

function updateOperationTaskDocument(operationTaskDocument) {
    return fetch(apiService.url + '/mobile/operation-task-documents/'+operationTaskDocument.ID, {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(operationTaskDocument)
    })
        .then(handleResponse)
        .then(operationTaskDocument => {
            return operationTaskDocument;
        });
}

function deleteOperationTaskDocument(id) {
    return fetch(apiService.url + '/mobile/operation-task-documents/'+id, {
        method: 'DELETE',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(operationTaskDocument => {
            return operationTaskDocument;
        });
}

function getDrivers(){
    return fetch(apiService.url + '/drivers', {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(drivers => {
            return drivers;
        });

}

function getOperationUsers(){
    return fetch(apiService.url + '/operation-users', {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(operationUsers => {
            return operationUsers;
        });

}

function getMeWithCountTask(){
    return fetch(apiService.url + '/me/count-task', {
        method: 'GET',
        headers: authHeader()
    })
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
